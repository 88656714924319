import { useState, useEffect, useMemo } from 'react'
import { Breakpoints as BreakpointsType } from 'types/theme'

import { breakpoints as themeBreakpoints } from 'styles/theme'

type BreakpointsBoolean = {
  [key in keyof BreakpointsType]: boolean
}

const useBreakpoint = () => {
  const getBreakpoints = () => {
    const isSSR = typeof window === `undefined`
    const bps: Partial<BreakpointsBoolean> = {}

    const keys = Object.keys(themeBreakpoints) as Array<keyof BreakpointsType>
    keys.forEach((key) => {
      const query = `(min-width: ${themeBreakpoints[key]}px)`
      const matches = isSSR || window.matchMedia(query).matches
      bps[key] = matches
    })

    return bps as BreakpointsBoolean
  }

  const [breakpoints, setBreakpoints] = useState<BreakpointsBoolean>(
    getBreakpoints()
  )

  const activeBP = useMemo(() => {
    const newarray = Object.keys(breakpoints).slice().reverse() as Array<
      keyof BreakpointsBoolean
    >
    return newarray.find((key) => breakpoints[key]) || 'xs'
  }, [breakpoints])

  useEffect(() => {
    const listener = () => setBreakpoints(getBreakpoints())

    window.addEventListener('resize', listener)
    return () => window.addEventListener('resize', listener)
  }, [])

  return { ...breakpoints, activeBP }
}

export default useBreakpoint
