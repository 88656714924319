import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import { Text } from 'components/shared/Typography'
import Icon from 'components/shared/Icon'

import { FOOTER_LINKS_BOTTOM } from 'constants/links'

import indigitalLogo from 'assets/icons/INDIGITAL.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  ${({ theme }) => theme.media.lg.max} {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.colors.darkgray};
  }
`

const LinksWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  & > * {
    margin: 0 20px 0 0;
  }
  & > a:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${({ theme }) => theme.media.md.max} {
    flex-direction: column;
    & > * {
      margin: 0 10px 5px 0;
      font-size: 12px;
    }
  }
`

const Creator = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.xs.max} {
    flex-direction: column;
  }
`

const CreatorLogo = styled.a`
  max-width: 90px;
  margin-left: 12px;
  ${({ theme }) => theme.media.xs.max} {
    margin-top: 0.5rem;
  }
`

const Copyright = () => {
  return (
    <Wrapper>
      <LinksWrapper>
        <Text size={13} weight={300} themecolor="light">
          Wszelkie prawa zastrzeżone
        </Text>
        {FOOTER_LINKS_BOTTOM.map(({ link, label }) => (
          <Text
            key={link}
            as={LocalizedLink}
            to={link}
            size={13}
            weight={300}
            themecolor="light"
          >
            {label}
          </Text>
        ))}
      </LinksWrapper>
      <Creator>
        <Text size={12} weight={300} themecolor="light">
          Wykonane przez
        </Text>
        <CreatorLogo href="https://landingpage.indigital.pl/" target="_blank">
          <Icon src={indigitalLogo} />
        </CreatorLogo>
      </Creator>
    </Wrapper>
  )
}

export default Copyright
