import React from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import Links from 'components/layout/Navigation/Sidenav/Links'

import { useTranslation } from 'react-i18next'

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'

type Props = {
  active: boolean
}

const Wrapper = styled.nav`
  position: fixed;
  width: 100vw;
  height: ${({ theme }) => `calc(100vh - ${theme.navigation.mobile})`};
  top: ${({ theme }) => theme.navigation.mobile};
  bottom: 0;
  right: 0;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: ${({ theme }) => theme.colors.dark};
  transform: translateX(100vw);
  ${({ theme }) => theme.media.sm.min} {
    width: 400px;
    transform: translateX(400px);
  }
`

const Overlay = styled.div<{ active: boolean }>`
  position: fixed;
  top: ${({ theme }) => theme.navigation.mobile};
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.35);
  z-index: 110;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      & > ${Wrapper} {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    `}
`

const InnerWrapper = styled.div`
  display: flex;
  overflow-y: auto;
  height: 100%;
  padding-top: 1rem;
  & > ${Container} {
    display: flex;
    flex-direction: column;
    height: calc(100% - 120px);
  }
`

const ButtonWrapper = styled.div`
  padding: 1rem 0 2rem;
`

const Sidenav: React.FC<Props> = ({ active }) => {
  const { t } = useTranslation('links')

  return (
    <Overlay active={active}>
      <Wrapper>
        <InnerWrapper>
          <Container>
            <Links />
            <ButtonWrapper>
              <Button width="100%" as={LocalizedLink} to="/znajdz-mieszkanie">
                <SearchIcon /> {t('findApartment')}
              </Button>
            </ButtonWrapper>
          </Container>
        </InnerWrapper>
      </Wrapper>
    </Overlay>
  )
}

export default Sidenav
