import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import { NAV_LINKS } from 'constants/links'

import { Text } from 'components/shared/Typography'
import Icon from 'components/shared/Icon'

import arrowDown from 'assets/icons/arrow-down.svg'

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 1rem 0;
`

const InnerList = styled.div`
  max-height: 0;
  opacity: 0;
  overflow-y: auto;
  transition: opacity 0.3s ease 0s, max-height 0.3s ease 0s;
  & > a {
    padding: 0.35rem 0.5rem;
  }
`

const LinkItem = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 1rem 0.5rem 1rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.darkgray};
  cursor: pointer;
  & + div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkgray};
    & > a {
      border: none;
    }
  }
  ${({ active }) =>
    active &&
    css`
      & > span {
        transform: rotate(180deg);
      }
      & + ${InnerList} {
        max-height: none;
        opacity: 1;
        overflow-y: initial;
        padding: 0.5rem 0;
      }
    `}
`

const Links = () => {
  const [openedDropdown, setOpenedDropdown] = useState<number | null>(null)

  const toggleDropdown = (index: number) => {
    if (openedDropdown === index) {
      setOpenedDropdown(null)
      return
    }
    setOpenedDropdown(index)
  }

  return (
    <LinksWrapper>
      {NAV_LINKS.map((item, index) => (
        <React.Fragment key={item.label}>
          {item.type === 'local' && (
            <LinkItem as={LocalizedLink} to={item.link}>
              <Text as="span" size={17} weight={400} themecolor="white">
                {item.label}
              </Text>
            </LinkItem>
          )}
          {item.type === 'dropdown' && (
            <>
              <LinkItem
                active={index === openedDropdown}
                onClick={() => toggleDropdown(index)}
              >
                <Text size={17} weight={400} themecolor="white">
                  {item.label}
                </Text>
                <Icon src={arrowDown} white size={18} marginLeft />
              </LinkItem>
              <InnerList>
                {item.dropdown.map((dropdownLink) => (
                  <LinkItem
                    as={LocalizedLink}
                    key={dropdownLink.label}
                    to={dropdownLink.link}
                  >
                    <Text size={15} weight={400} themecolor="white100">
                      {dropdownLink.label}
                    </Text>
                  </LinkItem>
                ))}
              </InnerList>
            </>
          )}
        </React.Fragment>
      ))}
    </LinksWrapper>
  )
}

export default Links
