import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'

import Button from 'components/shared/Button'
import Container from 'components/shared/Container'

const CookiesWrapper = styled.aside`
  position: fixed;
  bottom: 10px;
  left: 0;
  z-index: 100;
  width: 100%;
  display: block;
  transition: 0.3s ease;
  opacity: 1;
  max-width: max-content;
  ${Container} {
    max-width: max-content;
    margin: 0;
    ${({ theme }) => theme.media.sm.max} {
      padding: 0 10px;
    }
  }
  ${({ theme }) => theme.media.sm.min} {
    bottom: 20px;
  }
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}
`

const CookiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: ${({ theme }) => theme.colors.white50};
  border: 1px solid ${({ theme }) => theme.colors.lightgray};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 700px;
  padding: 20px 18px;
  border-radius: 10px;
  ${({ theme }) => theme.media.sm.min} {
    align-items: center;
    padding: 25px 35px;
  }
`

const CookiesButton = styled(Button)`
  width: 55px;
  height: 40px;
  color: ${({ theme }) => theme.colors.dark};
  margin-left: 5px;
  ${({ theme }) => theme.media.md.min} {
    margin-left: 15px;
  }
`

const CookiesText = styled.p`
  font-size: 0.85rem;
  line-height: normal;
  margin: 0;
  ${({ theme }) => theme.media.md.min} {
    padding-right: 15px;
    font-size: 1rem;
  }
`

const COOKIE_KEY = 'artbud-cookies-consent'

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)

  const acceptCookies = () => {
    Cookie.set(COOKIE_KEY, 'true')
    setVisible(false)
    setTimeout(() => setRemoveAlert(true), 500)

    localStorage.setItem(COOKIE_KEY, 'true')
  }

  useEffect(() => {
    const value = localStorage.getItem(COOKIE_KEY)
    if (value) {
      setVisible(false)
      setTimeout(() => setRemoveAlert(true), 500)
    } else {
      setVisible(true)
    }
  }, [])

  return (
    <>
      {!removeAlert && (
        <CookiesWrapper hidden={!visible}>
          <Container>
            <CookiesContainer>
              <CookiesText>
                Strona korzysta z plików cookies w celu realizacji usług. Możesz
                określić warunki przechowywania lub dostępu do plików cookies w
                Twojej przeglądarce
              </CookiesText>
              <CookiesButton outline onClick={acceptCookies}>
                OK
              </CookiesButton>
            </CookiesContainer>
          </Container>
        </CookiesWrapper>
      )}
    </>
  )
}

export default Cookies
