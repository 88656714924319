import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import { NAV_LINKS } from 'constants/links'

import { Text } from 'components/shared/Typography'
import Icon from 'components/shared/Icon'

import arrowDown from 'assets/icons/arrow-down.svg'
import arrowRight from 'assets/icons/arrow-right-orange.svg'

const LinkItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 6px;
  text-decoration: none;
  height: calc(100% - 2rem);
  white-space: nowrap;
  & > ${Text} {
    position: relative;
  }
  & > span:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.primary};
    transition: 0.35s;
    transform-origin: 0 50%;
    transform: scaleX(0);
  }
  &:hover > span:after {
    transform: scaleX(1);
  }
  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
  ${({ theme }) => theme.media.xxl.min} {
    margin: 0 20px;
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 6px 12px #00000029;
  border: 1px solid ${({ theme }) => theme.colors.white100};
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  border-radius: 8px;
  & > ${LinkItem} {
    position: relative;
    padding: 0.75rem 3rem 0.75rem 1rem;
    margin: 0;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.white100};
    }
    ${Text} {
      position: static;
      & > span {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        transform: translate3d(0, -50%, 0);
        transition: 0.25s;
      }
    }
    &:hover {
      ${Text} {
        color: ${({ theme }) => theme.colors.black};
        & > span {
          transform: translate3d(0.5rem, -50%, 0);
        }
      }
    }
  }
`

const Links = () => {
  return (
    <>
      {NAV_LINKS.map((item) => (
        <React.Fragment key={item.label}>
          {item.type === 'local' && (
            <LinkItem as={LocalizedLink} to={item.link}>
              <Text as="span" size={15} weight={400}>
                {item.label}
              </Text>
            </LinkItem>
          )}
          {item.type === 'dropdown' && (
            <LinkItem>
              <Text as="span" size={15} weight={400}>
                {item.label}
                <Icon src={arrowDown} marginLeft />
              </Text>
              <Dropdown>
                {item.dropdown.map((dropdownLink) => (
                  <LinkItem
                    as={LocalizedLink}
                    key={dropdownLink.label}
                    to={dropdownLink.link}
                  >
                    <Text as="div" size={14} weight={400} themecolor="darkgray">
                      {dropdownLink.label}
                      <Icon src={arrowRight} marginLeft />
                    </Text>
                  </LinkItem>
                ))}
              </Dropdown>
            </LinkItem>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default Links
