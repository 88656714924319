import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import { FOOTER_LINKS_MAIN } from 'constants/links'

import { Text } from 'components/shared/Typography'

const LinkItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding: 6px 0;
  white-space: nowrap;
  & > ${Text} {
    position: relative;
  }
  & > p:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.primary};
    transition: 0.35s;
    transform-origin: 0 50%;
    transform: scaleX(0);
  }
  &:hover > p:after {
    transform: scaleX(1);
  }
  ${({ theme }) => theme.media.lg.min} {
    padding: 0 7px;
    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.darkgray};
    }
  }
  ${({ theme }) => theme.media.xxl.min} {
    padding: 0 20px;
  }
`

const Links = () => {
  return (
    <>
      {FOOTER_LINKS_MAIN.map((item) => (
        <React.Fragment key={item.label}>
          {item.type === 'local' && (
            <LinkItem as={LocalizedLink} to={item.link}>
              <Text size={15} weight={300} themecolor="white">
                {item.label}
              </Text>
            </LinkItem>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default Links
