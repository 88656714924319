import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'
import { useTranslation } from 'react-i18next'

import FavouritesContext from 'contexts/FavouritesContext'

import Container from 'components/shared/Container'
import Icon from 'components/shared/Icon'
import Button from 'components/shared/Button'
import { Text } from 'components/shared/Typography'

import Links from 'components/layout/Navigation/Topnav/Links'
import Sidenav from 'components/layout/Navigation/Sidenav'

import useScrollHide from 'hooks/useScrollHide'
import useBreakpoint from 'hooks/useBreakpoint'

import toggleBodyOverflow from 'utils/toggleBodyOverflow'

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import artbudLogo from 'assets/icons/artbud-logo.svg'
import menuIcon from 'assets/icons/hamburger.svg'
import closeIcon from 'assets/icons/close-orange.svg'

const Wrapper = styled.nav<{ isHidden: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ theme }) => theme.navigation.mobile};
  z-index: 100;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  transition: 250ms ease transform;
  transform: translate3d(0, 0, 0);
  ${({ isHidden }) =>
    isHidden &&
    css`
      transform: translate3d(0, -100%, 0);
    `}

  ${({ theme }) => theme.media.lg.min} {
    height: ${({ theme }) => theme.navigation.desktop};
  }
`

const InnerWrapper = styled.div`
  height: 100%;
  & > ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  ${({ theme }) => theme.media.xl.max} {
    ${Text} {
      font-size: 14px;
    }
  }
`

const MainLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.media.lg.max} {
    display: none;
  }
`

const LogoWrapper = styled.div`
  ${({ theme }) => theme.media.lg.max} {
    max-width: 120px;
  }
`

const Logo = styled(LocalizedLink)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const Favourites = styled(LocalizedLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  margin: 0 10px;
  &:hover > ${Text} {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  min-height: 26px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  margin-right: 5px;
`

const ButtonWrapper = styled.div`
  white-space: nowrap;
  ${({ theme }) => theme.media.xl.max} {
    display: none;
  }
`

const MenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  min-width: 35px;
  min-height: 35px;
  ${({ theme }) => theme.media.lg.min} {
    display: none;
  }
`

const Navigation = () => {
  const [sidenavVisible, setSidenavVisible] = useState(false)

  const isHidden = useScrollHide(0, 200)
  const { favourites } = useContext(FavouritesContext)
  const { lg } = useBreakpoint()

  const { t } = useTranslation('links')

  const toggleSidenav = () => setSidenavVisible(!sidenavVisible)

  useEffect(() => {
    toggleBodyOverflow(sidenavVisible)
  }, [sidenavVisible])

  return (
    <>
      {!lg && <Sidenav active={sidenavVisible} />}
      <Wrapper isHidden={!sidenavVisible && isHidden}>
        <InnerWrapper>
          <Container navWidth fullHeight>
            <LogoWrapper>
              <Logo to="/">
                <Icon src={artbudLogo} alt="ART-BUD" />
              </Logo>
            </LogoWrapper>
            <LinksWrapper>
              <MainLinks>
                <Links />
              </MainLinks>
              <Favourites to="/ulubione">
                <Badge>
                  <Text themecolor="white" size={13}>
                    {favourites.length}
                  </Text>
                </Badge>
                <Text size={15}>{t('wishlist')}</Text>
              </Favourites>
              <MenuButton onClick={toggleSidenav}>
                {sidenavVisible ? (
                  <Icon src={closeIcon} size={18} />
                ) : (
                  <Icon src={menuIcon} size={23} />
                )}
              </MenuButton>
            </LinksWrapper>
            <ButtonWrapper>
              <Button as={LocalizedLink} to="/znajdz-mieszkanie">
                <SearchIcon /> {t('findApartment')}
              </Button>
            </ButtonWrapper>
          </Container>
        </InnerWrapper>
      </Wrapper>
    </>
  )
}

export default Navigation
