import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'

import type { SeoQuery, WpSeoFragment } from 'types/graphql'

type SeoProps = {
  title?: string
  seo?: WpSeoFragment
}

const Seo: React.FC<SeoProps> = ({ seo, title }) => {
  const query = useStaticQuery<SeoQuery>(
    graphql`
      query SEO {
        seoPL: wpPage(
          language: { slug: { eq: "pl" } }
          slug: { regex: "/strona-glowna/" }
        ) {
          seo {
            ...WpSEO
          }
        }
      }
    `
  )

  const { locale } = useLocalization()

  const defaultSEO = query?.seoPL?.seo
  if (!defaultSEO) throw new Error("CMS data didn't load properly")

  const SEO: WpSeoFragment = {
    title: title ?? seo?.title ?? defaultSEO.title,
    metaDesc: seo?.metaDesc ?? defaultSEO.metaDesc,
    opengraphTitle: seo?.opengraphTitle ?? defaultSEO.opengraphTitle,
    opengraphDescription:
      seo?.opengraphDescription ?? defaultSEO.opengraphDescription,
    twitterTitle: seo?.twitterTitle ?? defaultSEO.twitterTitle,
    twitterDescription:
      seo?.twitterDescription ?? defaultSEO.twitterDescription,
    opengraphImage: seo?.opengraphImage ?? defaultSEO.opengraphImage,
    twitterImage: seo?.twitterImage ?? defaultSEO.twitterImage,
  }

  const meta = [
    {
      property: `robots`,
      content: `index, follow`,
    },
    {
      name: `description`,
      content: SEO.metaDesc || '',
    },
    {
      property: `og:title`,
      content: SEO.opengraphTitle || SEO.title || '',
    },
    {
      property: `og:description`,
      content: SEO.opengraphDescription || SEO.metaDesc || '',
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: `inDigital`,
    },
    {
      name: 'author',
      content: 'inDigital',
    },
    {
      name: `twitter:title`,
      content: SEO.twitterTitle || SEO.title || '',
    },
    {
      name: `twitter:description`,
      content: SEO.twitterDescription || SEO.metaDesc || '',
    },
  ]

  if (SEO.opengraphImage?.sourceUrl) {
    meta.push({
      property: `og:image`,
      content: SEO.opengraphImage?.sourceUrl,
    })
  }

  if (SEO.twitterImage?.sourceUrl) {
    meta.push({ name: `twitter:image`, content: SEO.twitterImage?.sourceUrl })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={SEO.title || ''}
      titleTemplate="%s"
      meta={meta}
    />
  )
}

export default Seo
