import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import Icon from 'components/shared/Icon'
import Container from 'components/shared/Container'
import Button from 'components/shared/Button'

import Links from 'components/layout/Footer/Links'
import Copyright from 'components/layout/Footer/Copyright'

import artbudLogo from 'assets/icons/artbud-logo-white.svg'
import linkedinIcon from 'assets/icons/linkedin.svg'
import facebookIcon from 'assets/icons/facebook.svg'

const Wrapper = styled.footer`
  background: ${({ theme }) => theme.colors.dark};
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkgray};
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.sm.max} {
    max-width: 140px;
  }
`

const LinksWrapper = styled.div<{ mobile?: boolean }>`
  display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.media.lg.max} {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0 0;
    display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  }
`

const Logo = styled(LocalizedLink)`
  margin-right: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 20px;
  & > a {
    margin-left: 8px;
    ${({ theme }) => theme.media.sm.max} {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
    }
  }
`

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Container fullHeight>
        <InnerWrapper>
          <LogoWrapper>
            <Logo to="/">
              <Icon src={artbudLogo} alt="ART-BUD" />
            </Logo>
          </LogoWrapper>
          <LinksWrapper>
            <Links />
          </LinksWrapper>
          <ButtonWrapper>
            <Button
              as="a"
              href="https://www.linkedin.com/company/artbud-wrze%C5%9Bnia"
              outline
              darkgray
              round
              roundSize="50px"
              target="_blank"
            >
              <Icon src={linkedinIcon} />
            </Button>
            <Button
              as="a"
              href="https://www.facebook.com/artbud.eu/ "
              outline
              darkgray
              round
              roundSize="50px"
              target="_blank"
            >
              <Icon src={facebookIcon} />
            </Button>
          </ButtonWrapper>
        </InnerWrapper>
        <LinksWrapper mobile>
          <Links />
        </LinksWrapper>
        <Copyright />
      </Container>
    </Wrapper>
  )
}

export default Footer
