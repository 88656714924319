/* eslint-disable import/no-mutable-exports */
import i18n from 'i18next'

export type Dropdown = {
  type: 'dropdown'
  label: string
  dropdown: Link[]
}

export type Link = {
  type: 'local'
  label: string
  link: string
}

export type ExternalLink = {
  type: 'external'
  label: string
  link: string
}

export let NAV_LINKS: (Link | Dropdown)[] = []

export let FOOTER_LINKS_MAIN: (Link | ExternalLink)[] = []

export let FOOTER_LINKS_BOTTOM: (Link | ExternalLink)[] = []

export let CONTACT_LINKS: Link[] = []

const updateLinks = () => {
  CONTACT_LINKS = [
    {
      type: 'local',
      label: i18n.t('links:contactSaleOffices'),
      link: '/kontakt/biura-sprzedazy',
    },
    {
      type: 'local',
      label: i18n.t('links:contactCustomerService'),
      link: '/kontakt/obsluga-klienta',
    },
    {
      type: 'local',
      label: i18n.t('links:contactTechnicalService'),
      link: '/kontakt/serwis-techniczny',
    },
    {
      type: 'local',
      label: i18n.t('links:contactHeadquarters'),
      link: '/kontakt/siedziba-firmy',
    },
    {
      type: 'local',
      label: i18n.t('links:contactForMedia'),
      link: '/kontakt/pytania',
    },
  ]

  NAV_LINKS = [
    {
      type: 'local',
      label: i18n.t('links:offer'),
      link: '/oferta-mieszkan',
    },
    {
      type: 'local',
      label: i18n.t('links:aboutUs'),
      link: '/o-nas',
    },
    {
      type: 'local',
      label: i18n.t('links:sportSupport'),
      link: '/wspieramy-sport',
    },
    {
      type: 'local',
      label: i18n.t('links:logBuilding'),
      link: '/dziennik-budowy',
    },
    {
      type: 'dropdown',
      label: i18n.t('links:contact'),
      dropdown: CONTACT_LINKS,
    },
  ]

  FOOTER_LINKS_MAIN = [
    {
      type: 'local',
      label: i18n.t('links:aboutUs'),
      link: '/o-nas',
    },
    {
      type: 'local',
      label: i18n.t('links:completedInvestments'),
      link: '/inwestycje-zrealizowane',
    },
    {
      type: 'local',
      label: i18n.t('links:inProgressInvestments'),
      link: '/inwestycje-w-przygotowaniu',
    },
    {
      type: 'local',
      label: i18n.t('links:promotions'),
      link: '/promocje',
    },
  ]

  FOOTER_LINKS_BOTTOM = [
    {
      type: 'local',
      label: i18n.t('links:privacyPolicy'),
      link: '/polityka-prywatnosci',
    },
    {
      type: 'local',
      label: i18n.t('links:cookies'),
      link: '/cookies',
    },
  ]
}

if (i18n.isInitialized) updateLinks()
i18n.on('loaded', () => updateLinks())
i18n.on('languageChanged', () => updateLinks())
