import React from 'react'
import styled from 'styled-components'

import Navigation from 'components/layout/Navigation/Topnav'
import Footer from 'components/layout/Footer'
import Cookies from 'components/shared/Cookies'
// import PromoModal from 'components/layout/PromoModal'

import type { Colors } from 'types/theme'

type LayoutProps = {
  background?: keyof Colors
}

const Wrapper = styled.div<Pick<LayoutProps, 'background'>>`
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.navigation.mobile};
  background: ${({ theme, background }) => theme.colors[background ?? 'white']};
  ${({ theme }) => theme.media.lg.min} {
    padding-top: ${({ theme }) => theme.navigation.desktop};
  }
`

const Layout: React.FC<LayoutProps> = ({ children, background }) => {
  return (
    <Wrapper background={background}>
      <Navigation />
      <Cookies />
      {/* <PromoModal /> */}
      {children}
      <Footer />
    </Wrapper>
  )
}

export default Layout
